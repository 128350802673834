.content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  height: 100vh;
  padding: 16px;
}

.socialItem {
  display: inline-block;
  border-radius: 50%;
  transition: 0.25s;
  margin: 7px;

  &:focus-visible {
    box-shadow: 0 0 0 4px #a6bdee;
    outline: none;
  }

  .img {
    display: block;
    transition: inherit;
    margin: -5px;

    &:hover {
      filter: brightness(0);
    }
  }
}

.cv {
  margin-top: 20px;

  .btn {
    display: inline-block;

    padding: 12px 40px;

    background-color: #404040;
    border-color: #202020;
    border-radius: 22px;
    box-shadow: 0px 0px 10px 0px rgba(64, 64, 64, 0.3);

    cursor: pointer;
    color: #fff;
    transition: 0.25s ease-in-out;
  }
}

.btn:hover {
  box-shadow: 0px 0px 10px 0px rgba(64, 64, 64, 0.5);
  background-color: #000;
}

.btn:focus-visible {
  box-shadow: 0 0 0 4px #a6bdee;
  outline: none;
}
