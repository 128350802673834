import React, { useContext, useEffect } from "react"

import { Notifications } from "~/src/uikit"
import { NotificationContext } from "~/src/App"

import { edaIcon, perekrestokIcon, vkusvillIcon } from "./png"
import { goyaevIcon, hermitageIcon, restoclubIcon } from "./jpg"

import style from "./style.module.scss"
import { mdIcon, ostrovokImg, avitoImg, bronevikImg } from "./svg"
import { sutochnoImg, yandexTravelImg } from "./webp"

const Cottage = () => {
  useEffect(() => {
    document.title = "Mountain Cottage"
  }, [])

  const handleCopyClick = (text: string) => () => {
    navigator.clipboard.writeText(text)

    setNotificationText("Скопировано")
    setIsNotifyActive(true)
  }
  const { setIsNotifyActive, setNotificationText } = useContext(NotificationContext)

  const services = [
    {
      image: avitoImg,
      href: "https://www.avito.ru/kislovodsk/doma_dachi_kottedzhi/dom_200_m_na_uchastke_5_sot._2255805209?ysclid=m25yeoms94813021546"
    },
    {
      image: ostrovokImg,
      href: "https://ostrovok.ru/hotel/russia/kislovodsk/mid10480544/mountain_cottage_guest_house",
      customWidth: 60
    },
    {
      image: yandexTravelImg,
      href: "https://travel.yandex.ru/hotels/kislovodsk/mountain-cottage",
      customWidth: 80
    },
    {
      image: sutochnoImg,
      href: "https://kislovodsk.sutochno.ru/1442861",
      customWidth: 70
    },
    {
      image: bronevikImg,
      href: "https://bronevik.com/ru/hotel/russia/kislovodsk/mountain-cottage-mountain",
      customWidth: 60
    }
  ]

  const shops = [
    {
      name: "Яндекс Еда",
      imageSrc: edaIcon,
      imageAlt: "Yandex eats logo",
      link: "https://eda.yandex.ru/",
      color: "#fce000"
    },
    {
      name: "Маркет Деливери",
      imageSrc: mdIcon,
      imageAlt: "Market delivery logo",
      link: "https://market-delivery.yandex.ru/",
      color: "#0ec645"
    },
    {
      name: "Перекресток",
      imageSrc: perekrestokIcon,
      imageAlt: "Perekrestok logo",
      link: "https://www.perekrestok.ru/delivery/kislovodsk?modal=delivery",
      color: "rgb(12, 89, 46)"
    },
    {
      name: "Вкусвилл",
      imageSrc: vkusvillIcon,
      imageAlt: "Vkusvill logo",
      link: "https://www.perekrestok.ru/delivery/kislovodsk?modal=delivery",
      color: "#2dbe64"
    }
  ]

  return (
    <div className={style.content}>
      <Notifications />
      <h1 className={style.title}>Сервисы бронирований</h1>
      <div className={style.services}>
        {services.map(service => (
          <a className={style.serviceCard} href={service.href} target="_blank">
            <img src={service.image} className={style.image} style={{ maxWidth: `${service.customWidth}%` }} />
          </a>
        ))}
      </div>
      <h1 className={style.title}>Интернет</h1>
      <p>
        В доме две WiFi сети, 2.4GHz и 5GHz. 2.4GHz доступна во всем доме, но имеет среднюю скорость. 5GHz имеет высокую
        скорость, но меньший диапазон действия, отлично подходит для скачивания тяжелых файлов
      </p>
      <p onClick={handleCopyClick("Kislovodsk")}>
        Пароль: <b className={style.link}>Kislovodsk</b>
      </p>
      <h1 className={style.title}>Доставка продуктов</h1>
      <div className={style.items}>
        {shops.map(({ name, imageSrc, imageAlt, link, color }) => (
          <div key={name} className={style.card}>
            <div className={style.imageWrapper} style={{ background: color }}>
              <img className={style.image} src={imageSrc} alt={imageAlt} />
            </div>
            <div className={style.content}>
              <h3>{name}</h3>
              <span>
                <p>Адрес для доставки:</p>
                <b className={style.link} onClick={handleCopyClick("Пятигорская 129А")}>
                  Пятигорская 129А
                </b>
              </span>
              <a className={style.link} href={link}>
                Перейти
              </a>
            </div>
          </div>
        ))}
      </div>
      <h1 className={style.title}>Рестораны</h1>
      <div className={style.items}>
        <div className={style.card}>
          <img src={goyaevIcon} alt="Goyaev logo" className={style.logo} />
          <div className={style.content}>
            <h3>Шафран</h3>
            <p>Кирова 50</p>
            <a className={style.link} href="https://yandex.ru/maps/-/CCUVBAhjwA">
              Яндекс профиль
            </a>
          </div>
        </div>

        <div className={style.card}>
          <img src={restoclubIcon} alt="Restoclub logo" className={style.logo} />
          <div className={style.content}>
            <h3>Resto & club</h3>
            <p>Территориальная 2</p>
            <a className={style.link} href="https://yandex.ru/maps/-/CCUVBAXn0A">
              Яндекс профиль
            </a>
          </div>
        </div>
      </div>
      <h1 className={style.title}>Кафе</h1>
      <div className={style.items}>
        <div className={style.card}>
          <img src={hermitageIcon} alt="Hermitage logo" className={style.logo} />
          <div className={style.content}>
            <h3>Эрмитаж</h3>
            <p>Дзержинского 30</p>
            <a className={style.link} href="https://yandex.ru/maps/-/CCUCm6W4lC">
              Яндекс профиль
            </a>
          </div>
        </div>
      </div>
      <h1 className={style.title}>Маршруты</h1>
      <div className={style.route}>
        <p>Маршрут до верхнего парка (16 минут):</p>
        <a className={style.link} href="https://yandex.ru/maps/-/CCUVBEum-C">
          Яндекс карты
        </a>
      </div>
      <div className={style.route}>
        <iframe
          src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=88943133142"
          className={style.map}
          frameborder="0"
        />
      </div>
    </div>
  )
}

export default Cottage
